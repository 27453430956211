// PC.js
import React from 'react';

function PC() {
  return (
    <div className="left-col">
      <div className="top-heading">
        <h2>I-Need.co.il</h2>
        <p dir="rtl">כל המומחים במקום אחד</p>
      </div>
      <div className="text">
        <h2 dir="rtl">אנחנו כאן לחבר אותך למיטב המומחים.</h2>
        <h4 dir="rtl">הופכים את הכיוון, מהיום אתה לא צריך לחפש מומחים הם יפנו אליך.</h4>
        <p dir="rtl">אנחנו כאן לחבר אותך למיטב המומחים.</p>
        <p dir="rtl">אתה תבחר במה אתה צריך עזרה, איפה ומתי. ואנחנו נמצא את המומחה שמתאים לך בדיוק.</p>
        <p dir="rtl">אנו עובדים רק עם מקצוענים, אמינים ובמחירים נוחים. זמינות 24/7</p>
      </div>
      <img src="/images/main-desktop.png" className="img img-100" alt="Main Desktop" />
    </div>
  );
}

export default PC;
